import React from "react"

import { ToastNotificationsBaseProps, ToastNotificationsIconProps } from "."
import TextButton from "../../../elements/V2/Buttons/TextButton"
import GatsbyStoryblokImage from "../../../elements/V2/GatsbyStoryblokImage"
import Typography from "../../../elements/V2/Typography"

import { getAccentColor } from "@utils/V2/color"

type CountDownBannerProps = Omit<
  ToastNotificationsBaseProps & ToastNotificationsIconProps,
  "component"
>

const IconBanner = ({
  icon,
  text,
  iconAlt,
  ctaText,
  ctaLink,
  ctaColor,
  textColor,
  backgroundColor,
}: CountDownBannerProps) => {
  textColor = textColor || getAccentColor(backgroundColor)

  return (
    <div className="flex items-center gap-10">
      {!!icon && (
        <div className="h-24 min-w-24">
          <GatsbyStoryblokImage
            image={icon}
            aspectRatio="auto"
            alt={iconAlt || "Icon Banner"}
          />
        </div>
      )}
      <div className="inline-block">
        <Typography
          text={text}
          weight="book"
          font="grotesk"
          color={textColor}
          className="inline"
          size="body-sm-fixed"
        />{" "}
        <TextButton
          style="text"
          text={ctaText}
          linkUrl={ctaLink}
          textSize="body-sm-fixed"
          className="-mb-4 inline"
          color={ctaColor || textColor}
        />
      </div>
    </div>
  )
}

export default IconBanner
