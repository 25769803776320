import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"
import { SbGatsbyStory, storyblokEditable } from "gatsby-source-storyblok"
import React, {
  useLayoutEffect,
  lazy,
  useEffect,
  startTransition,
  useState,
  Suspense,
} from "react"

import ToastNotification from "./V2/Banners/ToastNotification"
import NavigationV2 from "./V2/Navigation"
import "aos/dist/aos.css"
import "./layout.css"

import { getUrlFromStoryblokLink, linkIsNotNull } from "@utils/storyblok"
import { mapNavigationMainLinks } from "@utils/V2/storyblok"

interface Props {
  children: any
}
interface QueryResult {
  footer: Storyblok.Page & SbGatsbyStory
  socialMedia: Storyblok.Page & SbGatsbyStory
  siteWideBanner: Storyblok.Page & SbGatsbyStory
}

type ToastBannerProps = {
  content: Array<
    | Storyblok.ToastNotificationIconBanner
    | Storyblok.ToastNotificationCountDownBanner
  >
}

export const ToastBanner = ({ content }: ToastBannerProps) => {
  return content.map((toastItem, index) => {
    if (toastItem.component === "toastNotificationCountDownBanner") {
      const item = toastItem as Storyblok.ToastNotificationCountDownBanner

      return (
        <ToastNotification
          // @ts-expect-error to have more strict typing compared to Storybloks generic object typing
          {...storyblokEditable(toastItem)}
          key={index}
          text={item.text}
          ctaText={item.ctaText}
          ctaColor={item.ctaColor}
          textColor={item.textColor}
          component={item.component}
          timerLabel={item.timerLabel}
          ctaTextColor={item.ctaTextColor}
          timerTextColor={item.timerTextColor}
          timerTargetDate={item.timerTargetDate}
          backgroundColor={item.backgroundColor}
          ctaLink={getUrlFromStoryblokLink(item.ctaLink)}
        />
      )
    } else {
      const item = toastItem as Storyblok.ToastNotificationIconBanner
      return (
        <ToastNotification
          // @ts-expect-error to have more strict typing compared to Storybloks generic object typing
          {...storyblokEditable(toastItem)}
          key={index}
          text={item.text}
          ctaText={item.ctaText}
          iconAlt={item.icon?.alt}
          ctaColor={item.ctaColor}
          icon={item.icon?.filename}
          textColor={item.textColor}
          component={item.component}
          backgroundColor={item.backgroundColor}
          ctaLink={getUrlFromStoryblokLink(item.ctaLink)}
        />
      )
    }
  })
}

const Layout = ({ children }: Props) => {
  // const [isBrowserSupported, setIsBrowserSupported] = useState<boolean>(false)
  const location = useLocation()
  const Footer = lazy(() => import("./Footer"))

  const [showFooter, setShowFooter] = useState(false)

  useEffect(() => {
    // Delay rendering the footer to ensure the children have loaded
    startTransition(() => {
      setShowFooter(true)
    })
  }, [])

  const queryResult: QueryResult = useStaticQuery(graphql`
    query StaticData {
      footer {
        uuid
        full_slug
        content
      }

      socialMedia {
        uuid
        full_slug
        content
      }

      siteWideBanner {
        uuid
        full_slug
        content
      }
    }
  `)

  const toastNotificationContent: (
    | Storyblok.ToastNotificationIconBanner
    | Storyblok.ToastNotificationCountDownBanner
  )[] = []

  /*
    A site wide banner can be set from config
  */
  let siteWideBannerData = queryResult?.siteWideBanner
    ?.content as Storyblok.SiteWideBanner
  // ToDo find out why this doesnt come back parsed from graphql query
  if (typeof siteWideBannerData === "string") {
    siteWideBannerData = JSON.parse(siteWideBannerData)
  }

  if (
    // ToDo clean up these two location checks into one
    // Hide site-wide banner on get started page
    !location.pathname.includes("/get-started") &&
    // Hide site-wide banner on /editor get started page
    !location.search.includes("/get-started") &&
    siteWideBannerData?.content?.length &&
    siteWideBannerData?.isEnabled === true
  ) {
    // Global banners always live at the top
    toastNotificationContent.push(...siteWideBannerData.content)
  }

  /* NOTE: A page can return navigation as a part of its story content or
     a page query can include a query of the default site header. The following
     logic prioritises custom navigation set from a page's story, then checks to
     see if a query of the default header was added, and finally sets headerData
     to undefined if no header information can be found. The header will not
     render in this case.
   */
  const pageData = children?.[children.length - 1]?.props?.data

  let headerData = pageData?.story?.content as Storyblok.PageContent
  if (typeof headerData === "string") {
    headerData = JSON.parse(headerData)
  }

  if (headerData?.toastNotificationContent?.length) {
    // Local, page-specific banners, live below any global banners
    toastNotificationContent.push(
      ...(headerData?.toastNotificationContent ?? [])
    )
  }
  // This is to cater for the 404 page query
  const mode = headerData?.navigationMode ? headerData.navigationMode : "dark"

  const headerContent = headerData
    ? headerData.navigation
    : // This is to cater for the 404 page query
      pageData?.headerV2?.content
      ? { content: JSON.parse(pageData.headerV2.content) }
      : undefined

  // TODO: re-enable this when we have a better way to detect supported browsers
  // see: https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent#rendering_engine_version
  // basically, checking only for user agent string is not a reliable way, and should
  // be avoided. It is also quite difficult to differentiate between webview and browser,
  // and is generaly not recommended. Also, browsers these days can kinda spoof the user
  // agent, which further makes it kinda irrelevant. The recommended solution is to detect
  // specific features, as that can be more reliable.
  //
  // const renderBanner = () => {
  //   return (
  //     <div className="bg-orange text-white w-full fixed left-0 top-0 py-8 px-16 z-50 flex justify-between items-center gap-4">
  //       <small>
  //         Your browser is not fully supported. Please consider upgrading to a
  //         modern browser for a better experience.
  //       </small>

  //       <small
  //         className="cursor-pointer hover:opacity-70"
  //         onClick={() => setIsBrowserSupported(true)}
  //       >
  //         <CloseIcon />
  //       </small>
  //     </div>
  //   )
  // }
  //
  // useLayoutEffect(() => {
  //   if ("requestAnimationFrame" in window && "cancelAnimationFrame" in window) {
  //     const ua = window.navigator.userAgent
  //     const supportedBrowsers = ["Firefox", "Chrome", "Safari"]

  //     for (const browser of supportedBrowsers) {
  //       if (ua.indexOf(browser) > -1) {
  //         setIsBrowserSupported(true)
  //         return
  //       }
  //     }
  //   }

  //   setIsBrowserSupported(false)
  // }, [])

  useLayoutEffect(() => {
    import("aos").then(({ default: AOS }) => {
      AOS.init({ once: true, offset: 120, duration: 500, easing: "ease" })
    })
  }, [])

  return (
    <>
      {/* 
        For /editor route:
          This will only render the siteWideBanner when not on the /settings/site-wide-banner/* story.
          This is done to prevent the site-wide-banner from rendering twice and breaking the 
          editor ui when on this path (ie: `editor/?path=settings/site-wide-banner/za`).

          See editor.tsx for rendering implementation of banners for the /editor route.

        For NON-/editor route: 
          This will render both siteWideBanner and any page banner
      */}
      {!location.search.includes("settings/site-wide-banner") &&
      toastNotificationContent.length ? (
        <ToastBanner content={toastNotificationContent} />
      ) : null}
      {headerContent ? (
        <div id="navigation">
          <NavigationV2
            mode={mode}
            // TODO: Refactor this to typecast as variable declaration and to compute mainLinks on component level
            mainLinks={mapNavigationMainLinks(
              (headerContent.content as Storyblok.Navigation).mainLinks
            )}
            primaryButtonText={headerContent.content.primaryCTAText}
            primaryButtonLink={
              headerContent.content.primaryCTALink &&
              linkIsNotNull(headerContent.content.primaryCTALink)
                ? getUrlFromStoryblokLink(headerContent.content.primaryCTALink)
                : undefined
            }
            secondaryButtonText={headerContent.content.secondaryCTAText}
            secondaryButtonLink={getUrlFromStoryblokLink(
              headerContent.content.secondaryCTALink
            )}
            mobilePrimaryButtonText={headerContent.content.mobilePrimaryCTAText}
            mobilePrimaryButtonLink={
              linkIsNotNull(headerContent.content.mobilePrimaryCTALink)
                ? getUrlFromStoryblokLink(
                    headerContent.content.mobilePrimaryCTALink
                  )
                : undefined
            }
            isBannerContents={toastNotificationContent?.length ? true : false}
            showCartWidget={headerContent.content.showCartWidget}
            hasSolidBackground={headerContent.content.hasSolidBackground}
          />
        </div>
      ) : null}
      <div className="flex min-h-screen flex-col">
        <main>{children}</main>
        {!headerData?.hide_footer && showFooter ? (
          <Suspense>
            <Footer
              footer={queryResult.footer}
              socialMedia={queryResult.socialMedia}
            />
          </Suspense>
        ) : null}
      </div>
    </>
  )
}

export default Layout
