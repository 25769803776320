import classNames from "classnames"
import { storyblokEditable } from "gatsby-source-storyblok"
import React, { useState } from "react"

import CountDownBanner from "./CountDownBanner"
import IconBanner from "./IconBanner"
import Section from "../../..//elements/V2/Section"
import { Color } from "../../../../constants/V2/color"

import CloseIcon from "@components/elements/V2/Icons/CloseIcon"
import { getBackgroundColorClass } from "@utils/V2/color"

export type ToastNotificationsBaseProps = {
  ctaText: string
  ctaLink: string
  ctaColor?: Color
  textColor?: Color
  backgroundColor: Color
}

export type ToastNotificationsCountDownProps = {
  text?: string
  timerLabel: string
  ctaTextColor?: Color
  timerTextColor?: Color
  timerTargetDate: string
  component: "toastNotificationCountDownBanner"
}

export type ToastNotificationsIconProps = {
  icon?: string
  iconAlt?: string
  text: string
  component: "toastNotificationIconBanner"
}

type Props = ToastNotificationsBaseProps &
  (ToastNotificationsCountDownProps | ToastNotificationsIconProps) &
  ReturnType<typeof storyblokEditable>

const ToastNotification = ({
  text,
  ctaText,
  ctaLink,
  ctaColor,
  textColor,
  backgroundColor,
  ...rest
}: Props) => {
  const [isClosed, setIsClosed] = useState(false)

  const storyblokProps = {
    "data-blok-c": rest["data-blok-c"],
    "data-blok-uid": rest["data-blok-uid"],
  }

  return (
    <Section
      {...storyblokProps}
      className={classNames(
        "flex h-[50px] items-center justify-between gap-10",
        getBackgroundColorClass(backgroundColor),
        { hidden: isClosed }
      )}
    >
      {rest.component === "toastNotificationCountDownBanner" ? (
        <CountDownBanner
          text={text}
          ctaText={ctaText}
          ctaLink={ctaLink}
          ctaColor={ctaColor}
          textColor={textColor}
          timerLabel={rest.timerLabel}
          backgroundColor={backgroundColor}
          timerTextColor={rest.timerTextColor}
          timerTargetDate={rest.timerTargetDate}
        />
      ) : (
        <IconBanner
          text={text!}
          icon={rest.icon}
          ctaText={ctaText}
          ctaLink={ctaLink}
          ctaColor={ctaColor}
          textColor={textColor}
          iconAlt={rest.iconAlt}
          backgroundColor={backgroundColor}
        />
      )}
      <div className="h-24 w-24 cursor-pointer">
        <CloseIcon
          width="24px"
          height="24px"
          hideBorder
          strokeWidth="2"
          strokeColor={textColor}
          onClick={() => setIsClosed(true)}
        />
      </div>
    </Section>
  )
}

export default ToastNotification
